import {
  GetSchoolStaffMemberRequest,
  SchoolStaffMember,
  SchoolStaffService,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { ISchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { joinKey } from '../key';
import { CommonParamsContext } from './../context';
import { CustomOpts, Procedure } from './../procedure';
import { QueryOpts } from './../query-opts';
import { useSchoolStaffClient } from './client';

type Params = { schoolName: string; name: string };

const keyPrefix = [SchoolStaffService.typeName, GetSchoolStaffMemberRequest.typeName] as const;

const makeKey = ({ name }: Pick<Params, 'name'>) => joinKey(keyPrefix, { name });

type Key = ReturnType<typeof makeKey>;

const getOpts = <T = SchoolStaffMember>(
  client: ISchoolStaffServiceClient,
  { schoolName, name }: Params,
): QueryOpts<SchoolStaffMember, T, Key> => ({
  queryKey: makeKey({ name }),
  queryFn: async () => client.getSchoolStaffMember({ school: schoolName, name }).response,
});

const useOpts = () => {
  const client = useSchoolStaffClient();
  const { schoolName } = useContext(CommonParamsContext);
  return <T = SchoolStaffMember>({ name }: Pick<Params, 'name'>) =>
    getOpts<T>(client, { schoolName, name });
};

const makeUseQuery =
  (suspense: boolean) =>
  <T = SchoolStaffMember>(
    { name }: Pick<Params, 'name'>,
    opts?: CustomOpts<SchoolStaffMember, T>,
  ) => {
    const { queryKey, queryFn } = useOpts()({ name });
    return useQuery({ queryKey, queryFn, ...opts, suspense });
  };

export const getSchoolStaffMember = {
  keyPrefix,
  makeKey,
  getOpts,
  useOpts,
  useQuery: makeUseQuery(false),
  useSuspenseQuery: makeUseQuery(true),
} satisfies Procedure<SchoolStaffMember, ISchoolStaffServiceClient, Params, Key>;
