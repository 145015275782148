import {
  ListSchoolStaffMembersRequest,
  SchoolStaffMember,
  SchoolStaffService,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { ISchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { joinKey } from '../key';
import { CommonParamsContext } from './../context';
import { CustomOpts, Procedure } from './../procedure';
import { QueryOpts } from './../query-opts';
import { useSchoolStaffClient } from './client';

type Params = { schoolName: string };

const keyPrefix = [SchoolStaffService.typeName, ListSchoolStaffMembersRequest.typeName] as const;

const makeKey = () => joinKey(keyPrefix, {});

type Key = ReturnType<typeof makeKey>;

const getOpts = <T = SchoolStaffMember[]>(
  client: ISchoolStaffServiceClient,
  { schoolName }: Params,
): QueryOpts<SchoolStaffMember[], T, Key> => ({
  queryKey: makeKey(),
  queryFn: async () =>
    client.listSchoolStaffMembers({ school: schoolName }).response.then(r => r.staffMembers),
});

const useOpts = () => {
  const client = useSchoolStaffClient();
  const { schoolName } = useContext(CommonParamsContext);
  return <T = SchoolStaffMember[]>() => getOpts<T>(client, { schoolName });
};

const makeUseQuery =
  (suspense: boolean) =>
  <T = SchoolStaffMember[]>(opts?: CustomOpts<SchoolStaffMember[], T>) => {
    const { queryKey, queryFn } = useOpts()();
    return useQuery({ queryKey, queryFn, ...opts, suspense });
  };

export const listSchoolStaffMembers = {
  keyPrefix,
  makeKey,
  getOpts,
  useOpts,
  useQuery: makeUseQuery(false),
  useSuspenseQuery: makeUseQuery(true),
} satisfies Procedure<SchoolStaffMember[], ISchoolStaffServiceClient, Params, Key>;
