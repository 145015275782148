import {
  GetSubscriptionDetailsRequest,
  SchoolSubscriptionDataActionsService,
  SubscriptionDetails,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import { ISchoolSubscriptionDataActionsServiceClient } from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions.client';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { CommonParamsContext } from '../context';
import { joinKey } from '../key';
import { CustomOpts, Procedure } from '../procedure';
import { QueryOpts } from './../query-opts';
import { useSubscriptionActionsClient } from './client';

type Params = { schoolName: string; product: Product };

const keyPrefix = [
  SchoolSubscriptionDataActionsService.typeName,
  GetSubscriptionDetailsRequest.typeName,
] as const;

const makeKey = ({ product }: Pick<Params, 'product'>) => joinKey(keyPrefix, { product });

type Key = ReturnType<typeof makeKey>;

const getOpts = <T = SubscriptionDetails>(
  client: ISchoolSubscriptionDataActionsServiceClient,
  { schoolName, product }: Params,
): QueryOpts<SubscriptionDetails, T, Key> => ({
  queryKey: makeKey({ product }),
  queryFn: async () => client.getSubscriptionDetails({ schoolName, product }).response,
});

const useOpts = () => {
  const client = useSubscriptionActionsClient();
  const { schoolName, thisProduct: product } = useContext(CommonParamsContext);
  return <T = SubscriptionDetails>() => getOpts<T>(client, { schoolName, product });
};

const makeUseQuery =
  (suspense: boolean) =>
  <T = SubscriptionDetails>(opts?: CustomOpts<SubscriptionDetails, T>) => {
    const { queryKey, queryFn } = useOpts()();
    return useQuery({ queryKey, queryFn, ...opts, suspense });
  };

export const getSubscriptionDetails = {
  keyPrefix,
  makeKey,
  getOpts,
  useOpts,
  useQuery: makeUseQuery(false),
  useSuspenseQuery: makeUseQuery(true),
} satisfies Procedure<
  SubscriptionDetails,
  ISchoolSubscriptionDataActionsServiceClient,
  Params,
  Key
>;
